import bgImg from './main-top-block-bg.png';
import logo from './icons/logo.png';
import './scss/fonts.scss'
import './app.scss';

function App() {
  return (
    <div className='container'>
      <div className='header'>
        <p className='logoText'>Фінансування від</p>
        <div className='logo'>
          <img alt='logo' src={logo} />
        </div>
      </div>
      <div className='shadowBlock'></div>
      <div className='bgImg'>
        <img alt='bg' src={bgImg}  />
      </div>
      <h1 className='titleMain'>Територія прозорого фінансування агровиробників!</h1>
      <div className='window'>
        <p className='title'>Вітаємо, дорогі громадяни</p>
        <p className='text'>В Україні війна. Весь цей час наша команда відправляла свої сімʼї в безпечні місця, допомагала армії та волонтерам, вела інформаційну війну з ворогом.</p>
        <p className='text'>Будь ласка, перебувайте у безпечному місці! Слідкуйте за новинами з офіційних джерел. Приєднуйтесь до IT-армії України! Записуйтесь у територіальну оборону! І головне — зберігайте спокій! Росія вже програла цю війну, просто розпочавши її.</p>
        <p className='text'>Допомагаємо один одному, бо тільки разом ми переможемо будь-якого ворога! <br/> Слава Україні!</p>
      </div>
    </div>
  );
}

export default App;